export function Footer() {
  return (
    <footer className="absolute bottom-2 left-0 right-0 text-center">
      <ins
        className="adsbygoogle h-[50px] md:h-[90px]"
        style={{
          display: "block",
          // width: "320px",
          // backgroundColor: "#CCCCCC",
        }}
        data-ad-client="ca-pub-4746633404428334"
        data-ad-slot="4370566560"
        data-full-width-responsive="true"
      ></ins>
    </footer>
  );
}
